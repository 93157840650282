<template>
  <div>
    <ik-data-table
      :entity-name="$t('unitGroup')"
      :model="model"
    >
      <template #item.unitIds="{item}">
        <span>{{ item.resourceUnits.map(it=>it.name).join(',') }}</span>
      </template>
    </ik-data-table>
  </div>
</template>

<script>

  import { IkDataTable } from 'metaflow-js'
  import UnitSet from '@/model/storage/UnitSet'

  export default {
    name: 'UnitSetPage',
    components: { IkDataTable },
    data: function () {
      return {
        model: UnitSet,
      }
    },
  }
</script>

<style scoped>

</style>
